<template>
  <div class="hide-div footer-poup">
    <div
      v-if="currentChatMember !== null"
      id="popup-button"
      class="open-button"
    >
      <div v-if="msgNotSeen" class="notification">
        {{ msgNotSeen }}
      </div>
      <div class="close-popup" @click="closePopup()">
        <div>x</div>
      </div>
      <div @click="openForm(currentChatMember)" class="chat-with">
        <div class="current-chat-member">
          {{ currentChatMember.firstname + " " + currentChatMember.lastname }}
        </div>
        <!-- <div style="margin-left: auto">
          <div v-if="online() == 0" class="connection-status">
            <span class="popup-offline"></span>
          </div>
          <div v-else class="connection-status">
            <span class="popup-online"></span>
          </div>
        </div> -->
      </div>
    </div>

    <div class="chat-popup" id="myForm">
      <div class="form-container">
        <Chat @reduce="reduceChatPopup()" :popUp="true"></Chat>
      </div>
    </div>
  </div>
</template>

<script>
import Chat from "../../views/Chat";
export default {
  name: "ChatPopup",
  components: { Chat },
  data() {
    return {};
  },

  computed: {
    alert() {
      return this.$store.getters.getAlertNewMsg;
    },
    currentChatMember() {
      return this.$store.getters.getCurrentChatMember;
    },
    msgNotSeen: {
      get() {
        const res = this.alert.filter(
          (el) => el.sender == this.currentChatMember.id
        )[0];
        return res ? res.nbrMsg : 0;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  methods: {
    online() {
      if (this.currentChatMember.id) {
        return this.$store.getters.getAttendees.filter(
          (el) => el.id == this.currentChatMember.id
        )[0].online;
      }
    },
    closePopup() {
      this.$store.state.displayChatPopup = false;
      document.getElementById("popup-button").style.display = "none";
    },
    reduceChatPopup() {
      document.getElementById("myForm").style.display = "none";
      this.msgNotSeen = 0;
    },
    openForm() {
      document.getElementById("myForm").style.display = "flex";
    },
    closeForm() {
      document.getElementById("myForm").style.display = "none";
    },
  },
};
</script>

<style lang="scss" scoped>
.footer-poup {
  color: white;
  //padding: 0px 10px;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: 0px;
  right: 40px;
  width: 320px;
  z-index:99;
  //hide modal
  display:none;
}
.chat-with {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.close-popup {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  margin: -20px 4px -3px auto;
  font-size: 20px;
}
.current-chat-member {
  display: flex;
  align-items: center;
  font-size: 20px;
}
.popup-online {
  height: 12px;
  width: 12px;
  background-color: rgb(82, 241, 82);
  border-radius: 50%;
  display: flex;
  align-items: center;
  margin: 5px 3px;
}
.popup-offline {
  @extend .popup-online;
  background-color: red;
}
/* Button used to open the chat form - fixed at the bottom of the page */
.connection-status {

  display: flex;
  margin-top: 5px;
  align-items: center;
}
.open-button {
  margin-left: 30px;
  margin-right: 17px;
  background-color: $main-color;
  color: white;
  opacity: 0.8;
  border-radius: 10px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  bottom: 0px;
  width: 20px;
  padding: 20px 5px 20px 20px;
  bottom: 0px;
  right: 28px;
  width: 280px;
  z-index: 9999;
}
.notification {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  font-size: 10px;
  color: #fff;
  line-height: 19px;
  text-align: center;
  background: red;
  margin-left: auto;
  position: fixed;
  margin: -38px 0px 30px 0px;
  //margin-top: -15px;
}
/* The popup chat - hidden by default */
.chat-popup {
  display: none;
  margin: 0px 0px 15px 15px;
  position: fixed;
  bottom: 65px;
  top:85px;
  overflow-y:auto;
  border-radius: 10px 10px 0px 0px;
  z-index: 9999;
  align-items: flex-end;
}

/* Add styles to the form container */
.form-container {
  max-width: 490px;
  width: 333px;
  background-color: white;
  border-radius: 10px 10px 0px 0px;
  max-height: calc(100vh - 180px);
}

/* Set a style for the submit/send button */
.form-container .btn {
  background-color: #4caf50;
  color: white;

  padding: 16px 20px;
  border: none;
  cursor: pointer;
  width: 100%;
  margin-bottom: 10px;
}


@media screen and (max-width: 1400px) {
  .hide-div {
    display: none;
  }
}


/* Add some hover effects to buttons */
</style>
