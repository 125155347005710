import store from "./store";
import axios from "axios";
import { socket, SERVER_URL } from "./main";
let sender = "";
function connectUserSocket(id) {
  socket.emit("user_connected", id);
  (sender = id), initialize();
}
function listenNewLoggedUser() {
  socket.on("user_connected", (id) => {
    makeUserOnline(id)
  });
}
function listenNewLoggedOutUser() {
  socket.on("user_logout", (id) => {
    var attendees = store.getters.getAttendees;
    var foundIndex = attendees.findIndex((x) => x.id == id);
    attendees[foundIndex].online = 0;
    store.commit("setAttendees", attendees);
  });
}
function getChatMemberById(id) {
  return store.getters.getAttendees
    .filter((el) => el.id === id)
    .map((item) => ({
      id: item.id,
      firstname: item.firstname,
      lastname: item.lastname,
      company: item.company,
      avatar: item.avatar,
      skype: item.skype,
      online: item.online,
    }))[0];
}
function listenNewMesssage() {
  socket.on("new_message", (data) => {
    makeUserOnline(data.sender)
    let chatList = store.getters.getChatList;
    let conv = store.getters.getMyConversations;
    conv.push(data);
    store.commit("setMyConversations", conv);
    const sender = chatList.filter((el) => el.id === data.sender)[0];
    if (sender) {
      const elementsIndex = chatList.findIndex(
        (element) => element.id == sender.id
      );
      chatList[elementsIndex].time = new Date();
    } else {
      let obj = getChatMemberById(data.sender);
      obj = { ...obj, time: new Date() };
      chatList.push(obj);
      chatList = chatList.sort((a, b) => new Date(b.time) - new Date(a.time));
    }
    store.commit("setMsgsChatBDD", data);
    store.commit("setChatList", chatList);
    incrimentMsgReceivde(data.sender, data.receiver);
  });
}
function listenNewCall() {
  socket.on("jitsi_call", (data) => {
    makeUserOnline(data.sender)
    store.commit("jitsi_call", data);
  });
}
function makeUserOnline(id){
  var attendees = store.getters.getAttendees;
    var foundIndex = attendees.findIndex((x) => x.id == id);
    attendees[foundIndex].online = 1;
    store.commit("setAttendees", attendees);
}
function incrimentMsgReceivde(sender, receiver) {
  let alert = store.getters.getAlertNewMsg;
  let exist = alert.some((el) => el.sender == sender);
  if (exist) {
    const index = alert.findIndex((el) => el.sender == sender);
    Object.assign(alert[index], {
      nbrMsg: alert[index].nbrMsg + 1,
      sender: alert[index].sender,
      receiver: alert[index].receiver,
    });
    alert = [...alert];
  } else {
    alert.push({ nbrMsg: 1, sender: sender, receiver });
  }
  store.commit("setAlertNewMsg", alert);
}
function messageNotSeen(messages) {
  let alert = store.getters.getAlertNewMsg;
  let messageNotSeenArr = messages
    .filter((el) => el.sender !== sender && el.isSeen == false)
    .map((item) => item.sender);
  messageNotSeenArr = messageNotSeenArr.filter(
    (item, index) => messageNotSeenArr.indexOf(item) === index
  );
  messageNotSeenArr.filter((el) => {
    let exist = store.getters.getAlertNewMsg.some((item) => item.sender == el);
    if (!exist) {
      alert.push({
        nbrMsg: messages.filter(
          (item) => item.sender == el && item.isSeen == false
        ).length,
        sender: el,
      });
      store.commit("setAlertNewMsg", alert);
    }
  });
}

function initialize() {
  axios
    .post(SERVER_URL+"/getMessages", {
      sender: sender,
    })
    .then((response) => {
      if (response.data.messages) {
        store.commit("setMyConversations", response.data.messages);
        let conversationsMembers = getConversationsMembers(
          response.data.messages
        );
        let chatList = store.getters.getAttendees
          .filter((el) => conversationsMembers.includes(el))
          .map((el) => ({
            id: el.id,
            firstname: el.firstname,
            lastname: el.lastname,
            company: el.company,
            avatar: el.avatar,
            skype: el.skype,
            online: el.online,
            time: response.data.messages
              .filter(
                (item) => item.receiver == el.id || item.receiver == sender
              )
              .slice(-1)
              .pop().time,
          }))
          .sort((a, b) => new Date(b.time) - new Date(a.time));
        store.commit("setChatList", chatList);
        store.commit("setCurrentChatMember", chatList[0]);
        messageNotSeen(response.data.messages);
      }
    })
    .catch((error) => {
      alert(error);
      console.log(
        "error in request: ",
        SERVER_URL+"/getMessages, error: ",
        error
      );
    });
}
function getConversationsMembers(messages) {
  let conversationsMembers = [];
  let member = [];
  messages.map((el) => {
    conversationsMembers.push(el.sender);
    conversationsMembers.push(el.receiver);
  });

  conversationsMembers = conversationsMembers.filter(function(item, pos, self) {
    return self.indexOf(item) == pos;
  });

  store.getters.getAttendees.filter((el) => {
    conversationsMembers.filter((item) => {
      if (item === el.id && item != sender) {
        member.push(el);
      }
    });
  });

  return member;
}
export {
  connectUserSocket,
  listenNewMesssage,
  listenNewCall,
  listenNewLoggedUser,
  listenNewLoggedOutUser,
};
