import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VTooltip from "v-tooltip";
import VueYoutube from "vue-youtube";
import VCalendar from "v-calendar";
import "./vee-validate";
import * as io from "socket.io-client";
import axios from "axios";
import main_color from "./variables.scss";
// Use v-calendar & v-date-picker components
Vue.use(VCalendar);

var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity",
  timeout: 10000,
  transports: ["polling"],
};
//export const SERVER_URL = "https://api-chat.my-virtual-convention.com";
export const SERVER_URL = "https://eu-api-chat.my-virtual-convention.com";

export const socket = io.connect(SERVER_URL, connectionOptions);
Vue.prototype.$SERVER_URL = SERVER_URL;
Vue.prototype.$socket = socket;
Vue.prototype.$axios = axios;
Vue.prototype.$main_color = main_color.main_color;
axios.interceptors.response.use(null, (error) => {
  if (error.response.status && error.response.status === 403) {
    if (router.currentRoute.path != "/login") {
      let infos = null
      if(store.state.LANG == 'FR') {        
        infos.title = 'Session expirée',
        infos.text = 'Votre session a expiré. Merci de vous connecter à nouveau.'
      }else {
        infos.title = 'Expired session',
        infos.text = 'Your session has expired. Please log in again.'
      }
      store.commit('openModalInfos', infos )
      store.commit("logout");
    } else {
      return "failed";
    }
  }
});

const token = localStorage.getItem("token");
if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}
Vue.config.productionTip = false;
Vue.use(VTooltip);
Vue.use(VueYoutube);

new Vue({
  router: router,
  store,
  render: (h) => h(App),
}).$mount("#app");
