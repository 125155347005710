var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"app"}},[_c('div',{attrs:{"id":"content"},on:{"click":_vm.closeDrop}},[(
        this.$route.name != 'Login' &&
        this.$route.name != 'ForgotPassword' &&
        this.$route.name != 'Register' &&
        this.$route.name != 'Welcome' &&
        this.$route.name != 'Inspection'
      )?_c('HeaderApp'):_vm._e(),_c('router-view'),(this.$store.state.MODAL_INFOS.show)?_c('ModalInfos'):_vm._e(),(
        this.$route.name != 'Login' &&
        this.$route.name != 'ForgotPassword' &&
        this.$route.name != 'Register' &&
        this.$route.name != 'Welcome' &&
        this.$route.name != 'Inspection'
      )?_c('FooterApp'):_vm._e(),_c('Error',{attrs:{"errorDisplay":this.$store.state.error.display,"errorMsg":this.$store.state.error.msg},on:{"errorClose":_vm.closeError}})],1),(this.$store.state.caller && this.$store.state.CONFIG.chat)?_c('IncommingCall',{attrs:{"contact":this.$store.state.caller},on:{"closeModal":_vm.closeCall}}):_vm._e(),(
      this.currentChatMember && this.$store.state.displayChatPopup == true && this.$store.state.CONFIG.chat
    )?_c('ChatPopup'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }