<template>
  <div :class="popUp ? '' : 'main-container'" v-if="this.$store.state.CONFIG.chat">
    <h1 v-if="!popUp">{{ this.$store.state.TRANSLATE.VIEWS.chat.title }}</h1>
    <div class="tips-container">
        <div class="tips-icon icon-otw_icon_bulb"></div>
        <div class="tips-content">
            <h3>{{ this.$store.state.TRANSLATE.VIEWS.chat.tipsTitle }}</h3>
            <p v-html="this.$store.state.TRANSLATE.VIEWS.chat.tips"></p>
        </div>
    </div>
    <div v-if="chatList.length > 0" :class="!popUp ? 'chat-container' : ''">
      <div v-if="!popUp" class="chat-menu-member">
        <div type class="search">
          <input
            class="input-search"
            :placeholder="this.$store.state.TRANSLATE.VIEWS.chat.search"
            type="text"
            v-model="searchName"
          />
        </div>
        <ChatReceiverItem
          v-for="item in MyFilterCOnversation"
          :key="item.id"
          :compName="'member'"
          :contact="item"
          :alerts="alert"
          @selectChatMmber="selectChatMember"
          :selectedMmber="receiver"
          @deleteConversation="deleteConversation"
        ></ChatReceiverItem>
      </div>
      <div :class="popUp ? 'chat-message-popup' : 'chat-message'">
        <div class="chat-header" v-if="chatMember">
          <ChatReceiverItem
            :compName="'header'"
            :contact="chatMember"
            @makeCall="callWithJitsiFct"
            @reduce="reducePopUp"
          ></ChatReceiverItem>
        </div>
        <div
          :class="!popUp ? 'container' : 'pop-up-container'"
          id="scroll-buttom"
        >
          <div v-for="msg in oldMsg" :key="msg.id" class="chat-history">
            <div v-if="msg.sender == sender" class="sender-message">
              <div class="sent-message-time">
                {{ dateCustomFormat(msg.time) }}
              </div>
              <div class="sent-message-content">{{ msg.message }}</div>
            </div>
            <div
              v-if="chatMember && msg.receiver == sender"
              class="receiver-message"
            >
              <div class="chat-with">
                <!-- <span v-if="online == 1" class="online"></span>
                <span v-if="online == 0" class="offline"></span> -->
                {{ chatMember.firstname + " " + chatMember.lastname }}
                <div class="message-time">
                  {{ dateCustomFormat(msg.time) }}
                </div>
              </div>

              <div class="received-message-content">{{ msg.message }}</div>
            </div>
          </div>
        </div>
        <div
          v-if="!popUp"
          style="width: 100%; display: flex; flex-direction: row; margin: 0px"
        >
          <!-- <div class="actions">
            <div class="empty-content" v-html="this.$store.state.TRANSLATE.VIEWS.chat.carefulMsg"></div>
            <div
              v-tooltip.bottom="{ content: this.$store.state.TRANSLATE.VIEWS.chat.export }"
              @click="exportPDF"
              class="export-icon icon-otw_icon_pdfexport"
            ></div>
          </div> -->

          <a
            v-tooltip.bottom="{ content: this.$store.state.TRANSLATE.VIEWS.chat.report }"
            :href="
              'mailto:support.mvc@synthes3d.com?subject=I want to report an abuse during a chat with the user ' +
              chatMember.firstname +
              ' ' +
              chatMember.lastname +
              ' - ' +
              this.$store.state.CONVENTION.name
            "
            class="hide-col menu-item link-report"
          >
            <div class="icon icon-otw_icon_reportanabuse"></div>
          </a>
        </div>

        <div :class="!popUp ? 'footer' : 'pop-up-footer'">
          <textarea
            :class="!popUp ? 'message-to-send' : 'pop-up-message-to-send'"
            :placeholder="this.$store.state.TRANSLATE.VIEWS.chat.typeMsg"
            rows="3"
            v-model="messageToSend"
            @mousedown.left="msgRead"
          ></textarea>
          <div
            v-if="popUp"
            @click="sendMessage"
            class="icon-send icon-otw_icon_sendmail"
          ></div>
          <div
            @click="sendMessage"
            class="hide-col-desktop icon-send icon-otw_icon_sendmail"
          ></div>
        </div>

        <button v-if="!popUp" class="hide-col btn" @click="sendMessage">{{ this.$store.state.TRANSLATE.VIEWS.chat.send }}</button>
      </div>
    </div>

    <div class="empty-chat-member" v-else>
      <div class="empty-chat-title">
        {{this.$store.state.TRANSLATE.VIEWS.chat.begin}}
      </div>

      <div class="empty-chat-content">
          <p v-html="this.$store.state.TRANSLATE.VIEWS.chat.description1"></p>
          <p v-html="this.$store.state.TRANSLATE.VIEWS.chat.description2" v-if="this.$store.state.CONFIG.networking"></p>
          <p v-html="this.$store.state.TRANSLATE.VIEWS.chat.description3"></p>
      </div>
    </div>
  </div>
</template>

<script>
import { jsPDF } from "jspdf";
// import * as io from "socket.io-client";
import "../router";
export default {
  name: "Chat",
  props: ["popUp"],
  components: {
    ChatReceiverItem: () => import("../components/chat/ChatReceiverItem"),
  },
  data() {
    return {
      sender: this.$store.getters.getAttendees
        ? this.$store.getters.getAttendees.filter(
            (el) =>
              el.firstname == this.$store.getters.getUser.firstname &&
              el.lastname == this.$store.getters.getUser.lastname
          )[0].id
        : null,
      receiver: "",
      messageToSend: "",
      searchName: "",
      respMode: false,
      error: false,
      oldMsg: [],
    };
  },
  watch: {
    msgChatBdd() {
      if (this.$route.name == "Chat") {
        if (this.msgChatBdd.sender == this.receiver) {
          this.getMessages(this.sender, this.receiver);
        }
        if (this.chatList.length == 1) {
          setTimeout(() => {
            this.selectChatMember(this.chatList[0]);
            this.chatMember = this.chatList[0];
          }, this.chatList[0]);
        }
      }
    },
  },
  computed: {
    oldMessages: {
      get() {
        return this.$store.getters.getOldMessages;
      },
      set(newValue) {
        this.$store.commit("setOldMessages", newValue);
        return newValue;
      },
    },
    chatMember: {
      get() {
        const mmbr = this.$store.getters.getCurrentChatMember;
        if (mmbr) {
          return mmbr;
        } else {
          return {};
        }
      },
      set(newValue) {
        this.$store.commit("setCurrentChatMember", newValue);
        return newValue;
      },
    },
    msgChatBdd() {
      return this.$store.getters.getMsgsChatBDD;
    },
    online() {
      const res = this.$store.getters.getAttendees.filter(
        (el) => el.id == this.chatMember.id
      )[0];
      return res ? res.online : 0;
    },
    attendees() {
      return this.$store.getters.getAttendees;
    },
    MyFilterCOnversation() {
      let myConversations = this.chatList.reduce((accumalator, current) => {
        if (!accumalator.some((item) => item.id === current.id)) {
          accumalator.push(current);
        }
        return accumalator;
      }, []);
      if (this.searchName !== "") {
        return myConversations
          .filter(
            (item) =>
              item.lastname
                .toLowerCase()
                .includes(this.searchName.toLowerCase()) ||
              item.firstname
                .toLowerCase()
                .includes(this.searchName.toLowerCase())
          )
          .filter((el) => el.id != this.sender.id);
      } else {
        return myConversations.filter((el) => el.id != this.sender.id);
      }
    },
    allMyConversations: {
      get() {
        return this.$store.getters.getMyConversations;
      },
      set(newValue) {
        this.$store.commit("setMyConversations", newValue);
        return newValue;
      },
    },
    alert: {
      get() {
        return this.$store.getters.getAlertNewMsg;
      },
      set(newValue) {
        return newValue;
      },
    },
    chatList: {
      get() {
        return this.$store.getters.getChatList;
      },
      set(newValue) {
        newValue = newValue.sort((a, b) => new Date(b.time) - new Date(a.time));
        this.$store.commit("setChatList", newValue);
        return newValue;
      },
    },
    newChatMember: {
      get() {
        return this.$store.getters.getSelectChatMember;
      },
      set(newValue) {
        return newValue;
      },
    },
  },
  created() {
    setTimeout(() => {
      this.selectChatMember(this.chatList[0]);
      if (this.newChatMember.id) {
        this.selectChatMember(this.newChatMember);
      }
    }, this.chatList[0]);
  },
  methods: {
    reducePopUp() {
      if (this.popUp) {
        this.$emit("reduce");
      }
    },
    callWithJitsiFct() {
      this.chatList
        .filter((el) => el.id == this.receiver)
        .map((item) => (item.time = new Date())),
        (this.chatList = this.chatList.sort(
          (a, b) => new Date(b.time) - new Date(a.time)
        ));

      this.selectChatMember(this.chatList[0]);
      let link = Math.random().toString(36).substr(2, 15);
      this.$store.commit("setJitsiLink", link);
      this.$router.push("/call-video");
      this.$socket.emit("send_jitsi_link", {
        sender: this.sender,
        receiver: this.receiver,
        link: link,
      });
    },
    exportPDF() {
      // Default export is a4 paper, portrait, using millimeters for units
      const doc = new jsPDF();
      doc.setFontSize(9);
      let sender = this.attendees.filter((el) => el.id == this.sender)[0];
      let receiver = this.attendees.filter((el) => el.id == this.receiver)[0];
      doc.text(
        "My conversation with " + receiver.firstname + " " + receiver.lastname,
        50,
        10
      );
      let pos = 20;
      let updatedData = this.oldMessages
        .map((x) =>
          x.sender === this.sender
            ? { ...x, sender: sender.firstname + " " + sender.lastname }
            : x
        )
        .map((x) =>
          x.receiver === this.sender
            ? { ...x, sender: receiver.firstname + " " + receiver.lastname }
            : x
        );

      updatedData.forEach((element) => {
        doc.text(element.sender + ": " + element.message, 10, pos);
        pos += 10;
      });
      doc.save(
        receiver.firstname + " " + receiver.lastname + " conversation.pdf"
      );
    },
    deleteConversation(id) {
      this.$store.commit(
        "setMyConversations",
        this.allMyConversations.filter((el) => el.sender != id)
      );

      this.$store.commit(
        "setAlertNewMsg",
        this.alert.filter((el) => el.sender !== id)
      );
      this.chatList = this.chatList.filter((el) => el.id !== id);
      this.$store.commit(
        "setChatList",
        this.chatList.filter((el) => el.id !== id)
      );
      setTimeout(() => {
        if (this.chatList.length > 0) {
          this.selectChatMember(this.chatList[0]);
        } else {
          this.$store.state.displayChatPopup = false;
        }
      }, this.chatList[0]);
      this.receiver = "";
      this.chatMember = {};
      // this.oldMessages = [];
    },
    fixScrollBottom() {
      if (this.$el) {
        let container = this.$el.querySelector("#scroll-buttom");
        if (container) {
          container.scrollTop = container.scrollHeight;
        }
      }
    },
    hourUS(hour) {
      let hours = hour.split(":");
      if (hours[0] > 12) {
        return hours[0] - 12 + ":" + hours[1] + " PM";
      } else {
        return hour + " AM";
      }
    },
    selectChatMember(member) {
      if (member) {
        this.$store.state.displayChatPopup = true;
        if (Object.keys(this.newChatMember).length !== 0) {
          this.chatMember = { ...this.newChatMember };
          this.receiver = this.newChatMember.id;
          const exist = this.chatList.map(
            (el) => el.id === this.newChatMember.id
          )[0];
          if (!exist) {
            this.chatList.push({ ...this.chatMember, time: new Date() });
          } else {
            this.$store.commit("setSelectChatMember", {});
          }
          this.chatList = this.chatList.sort(
            (a, b) => new Date(b.time) - new Date(a.time)
          );
        } else {
          this.chatMember = { ...member };
        }
        this.receiver = member.id;
        this.searchName = "";
        this.msgRead();
        this.getMessages(this.sender, this.receiver);
      }
    },
    dateCustomFormat(date) {
      const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      const dateFormat = new Date(date);
      return (
        monthNames[dateFormat.getMonth()] +
        " " +
        dateFormat.getDate() +
        ", " +
        // dateFormat.getFullYear() +
        "" +
        this.hourUS(dateFormat.getHours() + ":" + dateFormat.getMinutes())
      );
    },
    msgRead() {
      const exist = this.alert.filter((el) => el.receiver == this.sender);
      if (this.alert.length > 0 && exist) {
        let arr = [...this.alert.filter((el) => el.sender !== this.receiver)];
        this.alert = [].concat(arr);
        this.$store.commit("setAlertNewMsg", arr);
        this.$axios
          .post(this.$SERVER_URL + "/updateMessage", {
            sender: this.sender,
            receiver: this.receiver,
          })
          .then(() => {
            // console.log(response);
          })
          .catch((error) => {
            console.log(error);
            console.log("error in updateMessage  in Chat.vue");
          });
      }
    },
    sendMessage() {
      if (this.messageToSend != "") {
        let obj = {
          sender: this.sender,
          receiver: this.receiver,
          message: this.messageToSend,
          time: new Date(),
        };
        this.$socket.emit("send_message", obj);
        this.allMyConversations.push(obj),
          (this.getMessages(this.sender, this.receiver),
          (this.messageToSend = "")),
          this.chatList
            .filter((el) => el.id == this.chatMember.id)
            .map((item) => (item.time = new Date())),
          (this.chatList = this.chatList.sort(
            (a, b) => new Date(b.time) - new Date(a.time)
          ));
        setTimeout(() => {
          this.fixScrollBottom();
        }, this.oldMessages);
      }
    },
    getMessages(sender, receiver) {
      //this.chatMember = this.attendees.filter((el) => el.id == receiver)[0];
      this.receiver = this.chatMember.id;
      this.oldMsg = this.allMyConversations.filter(
        (el) => el.receiver == receiver || el.sender == receiver
      );
      setTimeout(() => {
        this.fixScrollBottom();
      }, this.oldMsg);
    },
  },
};
</script>

<style lang="scss" scoped>
#chat-msg-resp {
  display: none;
}
.icon {
  margin: 5px 2px 5px auto;
  color: grey;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.icon:hover {
  color: $main-color;
}
.icon-send {
  @extend .icon;
  margin: 5px;
}
.export-icon {
  margin: 5px;
  color: grey;
  cursor: pointer;
  font-size: 15px;
}
.export-icon:hover {
  color: $main-color;
}
.actions {
  display: flex;
  flex-direction: row;
}
.chat-menu-member {
  width: 23%;
  //min-height: calc(100vh - 330px);
  float: left;
  border: 1px solid $grey;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  height: 700px;
}
.chat-message {
  width: 75%;
  //min-height: calc(100vh - 3000px);
  margin-left: auto;
  order: 2;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}
.chat-message-popup {
  @extend .chat-message;
  width: 100%;
  height: calc(100vh - 230px);
  min-height:400px;
  z-index:99;
}
.chat-header {
  background-color: $light-grey;
  border-radius: 10px 10px 0px 0px;
  height: 60px;
  display: flex;
  align-items: center;
  flex-direction: row;
  border: 1px solid $grey;
  border-bottom: 0px;
}
.sender-message {
  margin: 5px 5px 0px auto;
  display: flex;
  flex-direction: column;
}
.container {
  max-height: 420px;
  min-height: 420px;
  overflow-y: auto;
  border: 1px solid $grey;
  padding: 5px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.pop-up-container {
  height:100%;
  // max-height: 560px;
  // min-height: 560px;
  overflow-y: auto;
  border: 1px solid $grey;
  padding: 5px;
}
.sent-message-content-pop {
  @extend .sent-message-content;
  color: white;
  width: 80px;
  height: auto;
  overflow: hidden;
  padding: 10px;
  background-color: #dcf8c6;
  margin-left: auto;
  font-size: 15px;
  color: $text-color;
  border-radius: 20px 20px 0px 20px;
  text-align: left;
}

.received-message-content-pop {
  @extend .received-message-content;
  border-radius: 20px 20px 20px 0px;
  background-color: $light-grey;
  margin-right: auto;
  margin-left: 30px;
}
.sent-message-content {
  background: #4caf50;
  color: white;
  width: 80%;
  height: auto;
  overflow: hidden;
  padding: 10px;
  background-color: #dcf8c6;
  margin-left: auto;
  font-size: 15px;
  color: $text-color;
  border-radius: 20px 20px 0px 20px;
  text-align: left;
}
.receiver-message {
  display: flex;
  flex-direction: column;
  margin: 5px auto 0px 5px;
}
.received-message-content {
  @extend .sent-message-content;
  border-radius: 20px 20px 20px 0px;
  background-color: $light-grey;

  margin-left: 15px;
}
.chat-with {
  font-size: 11px;
  text-align: left;
  color: $text-color;
  display: flex;
  flex-direction: row;
}
.footer {
  width: 60%;
  display: flex;
  justify-content: center;
  margin: 15px auto;
}
.pop-up-footer {
  display: flex;
  margin: auto 0px auto 0px;
  border: 1px solid $grey;
  padding: 10px;
}
.message-to-send {
  height: 100px;
  min-height: 100px;
  // overflow-y: auto;
}
.pop-up-message-to-send {
  height: 20px;
  min-height: 20px;
  overflow-y: auto;
}
.message-time {
  font-size: 11px;
  color: $text-color;
  margin-left: 5px;
}
.sent-message-time {
  @extend .message-time;
  margin-left: auto;
}
.empty-content {
  margin-top: 5px;
  font-size: 12px;
  font-style: italic;
}
.empty-chat-member {
  margin: 40px auto;
  width: 50%;
}
.empty-chat-title {
  margin-bottom: 20px;
  font-size: 16px;
  font-family: $font-bold;
  color: $text-color;
  text-align: center;
  margin-top: -45px;
}
.menu-item {
  margin-left: auto;
}
.empty-chat-content {
  margin-bottom: 20px;
  font-size: 16px;
  color: $text-color;
  text-align: center;
}
.online {
  height: 10px;
  width: 10px;
  background-color: green;
  border-radius: 50%;
  display: inline-block;
  margin: auto 3px;
}
.offline {
  height: 10px;
  width: 10px;
  background-color: red;
  border-radius: 50%;
  display: inline-block;
  margin: auto 3px;
}
.chat-history {
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
}
textarea {
  height: 200px;
  width: 100%;
  resize: none;
  border: 1px solid $grey;
  border-radius: 10px;
  padding: 10px;
}

.chat-container {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.search {
  margin: 18px;
  width: 90%;
  height: 35px;
  border: 1px solid $grey;
  border-radius: 50px;
  display: flex;
  align-items: center;
}
.search-icon {
  color: $main-color;
  margin-left: 10px;
  font-size: 15px;
}
.input-search {
  border: 0px;
  margin: auto 5px;
  height: 100%;
  width: 80%;
  padding: 0px;
}
.btn {
  margin: auto;
}
.hide-col-desktop {
  display: none;
}

.link-report {
  text-decoration: none;
}

.tips-container {
  width:90%;
  color:$text-color;
  max-width: 1200px;
  text-align: center;
  margin-bottom:40px;
  padding:20px 10px;
  border:1px solid $main-color;
  border-radius:10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.tips-icon {
  color:$main-color;
  font-size:50px;
  min-width: 120px;
}

.tips-content h3 {
  color:$text-color;
  padding:0;
  margin:0;
}

.tips-container p {
  text-align: left;
  padding-top:10px;
}

@media screen and (max-width: 1400px) {
  .icon-send {
    margin: 12px 0px 0px 20px;
    font-size: 20px;
  }
  .hide-col,
  .actions {
    display: none;
  }
  .hide-col-desktop {
    display: block;
  }

  .chat-message {
    width: 100%;
    //display: none;
  }
  .chat-container {
    width: 90%;
    flex-direction: column;
  }
  .footer {
    width: 80%;
  }
  .message-to-send {
    height: 20px;
    min-height: 10px;
  }

  .chat-menu-member {
    width: 100%;
    display: block;
  }
  .search {
    display: none;
  }
  .chat-menu-member {
    margin-left: 1px;
    margin-bottom:10px;
    display: flex;
    flex-direction: row;
    height: 10vh;
    overflow: auto;
    overflow-y: hidden;
  }

  .container {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

}

@media screen and (max-width: 768px) {
  .tips-container  {
    flex-direction: column;
  }

  .tips-container  p {
    margin:10px auto;
    padding-top:0;
  }
}

@media screen and (max-height: 630px) {
  .chat-message-popup {
    min-height: auto;
  }
}
</style>
