<template>
  <div class="stand-modal">
    <div class="header">
     {{ `${this.$store.state.TRANSLATE.VIEWS.chat.call} ${contact.firstname} ${contact.lastname }` }} 
    </div>
    <div class="call-avatar">
      <div class="call-animation">
        <div v-if="contact.avatar">
          <img
            class="avatar-container"
            :style="
              'background:url(' +
              this.$store.state.BASE_URL +
              '/file/' +
              contact.avatar +
              '?t=' +
              this.$store.getters.getToken +
              ')'
            "
            width="135"
          />
        </div>
        <div v-else class="avatar-default" :style="{ background: bgColor }">
          <div v-if="contact.firstname != null && contact.lastname != null">
            {{ contact.firstname.substr(0, 1) + contact.lastname.substr(0, 1) }}
          </div>
        </div>
      </div>
    </div>
    <div class="call-actions">
      <div @click="accept" class="accept">
        <img src="../../assets/img/accepte.png" class="img-options" alt="" />
      </div>
      <div class="reject">
        <img
          @click="reject"
          src="../../assets/img/reject.png"
          class="img-options"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "IncomingCall",
  props: ["contact"],

  data() {
    return { bgColor: null };
  },
  created() {
    let hue = Math.floor(Math.random() * 360);
    this.bgColor = "hsl(" + hue + ", 100%, 80%)";
  },
  computed: {},
  methods: {
    accept() {
      // let routeData = this.$router.resolve({
      //   name: "Jitsi",
      //   query: { data: "someData" },
      // });
      // window.open(routeData.href, "_blank");
      this.$emit("closeModal");
      this.$router.push("/call-video");
    },
    reject() {
      //this.$router.push("/call-video");
      this.$emit("closeModal");
    },
    // closeModal() {
    //   this.$emit("closeModal");
    // },
    // deleteItem() {
    //   this.$emit("confirmdelete");
    // },
  },
};
</script>

<style lang="scss" scoped>
.stand-modal {
  background-color: rgba(grey, 0.3);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 500px;
  height: 380px;
  //border: 1px solid;
  top: -20px;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}
.call-avatar {
  //border: 1px solid;
  height: 200px;
  margin: 5px;
}
.call-actions {
  //border: 1px solid;
  height: 80px;
  display: flex;
  flex-direction: row;
  margin-right: 100px;
  margin-left: 100px;
}
.accept {
  margin-right: auto;
  display: flex;
  align-items: center;
  position: relative;
}
.reject {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.img-options {
  width: 60px;
  height: 60px;
  position: relative;
  cursor: pointer;
}
.avatar-default {
  width: 130px;
  margin: auto;
  height: 130px;
  border-radius: 50%;
  overflow: hidden;
  color: #fff;
  font-size: 50px;
  font-family: $font-bold;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

.avatar-container {
  //   width: 150px;
  margin: auto;
  //   height: 150px;
  border-radius: 50%;
  overflow: hidden;
  background-position: center !important;
  background-size: cover !important;
  background-repeat: no-repeat;
}

.call-animation {
  border: 1px solid;
  margin: auto;
  //   overflow: hidden;
  cursor: pointer;
  width: 130px;
  height: 130px;
  //   position: relative;
  margin: 50px auto auto auto;
  border-radius: 100%;
  border: solid 5px #fff;
  animation: play 2s ease infinite;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}
img {
  width: 130px;
  height: 130px;
  border-radius: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
}
@keyframes play {
  0% {
    transform: scale(1);
  }
  15% {
    box-shadow: 0 0 0 5px rgba($main-color, 0.2);
  }
  25% {
    box-shadow: 0 0 0 10px rgba($main-color, 0.2),
      0 0 0 20px rgba($main-color, 0.2);
  }
  25% {
    box-shadow: 0 0 0 15px rgba($main-color, 0.4),
      0 0 0 30px rgba($main-color, 0.2);
  }
  25% {
    box-shadow: 0 0 0 20px rgba($main-color, 0.4),
      0 0 0 40px rgba($main-color, 0.2);
  }
  25% {
    box-shadow: 0 0 0 25px rgba($main-color, 0.4),
      0 0 0 50px rgba($main-color, 0.2);
  }
}

.error-message {
  margin: auto auto -13px auto;
  color: red;
}

.edit-stand-modal {
  position: fixed;
  width: 600px;
  height: 433px;
  top: -20px;
  margin: auto;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($light-grey, 0.9);
  margin: auto;
  display: flex;
  flex-direction: column;
  z-index: 9999;
  border-radius: 10px;
  border: 1px solid $grey;
}
.header {
  padding: 20px;
  font-size: 20px;
  color: #fff;
  background: rgba($main-color, 0.9);
  border: 1px solid $main-color;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px 10px 0px 0px;
}
.stand-modal-title {
  margin: auto 0px auto auto;
  color: white;
  font-size: 25px;
  font-family: $font-bold;
  font-weight: 700;
}
.stand-modal-box {
  display: flex;
  flex-direction: column;
  margin: auto;
}
.edit-modal-box {
  display: flex;
  flex-direction: column;
  margin: 20px auto 20px auto;
}
.form-row {
  display: flex;
  flex-direction: row;
}
.form-row label {
  font-size: 14px;
  line-height: 1.5;
  color: $main-color;
  font-family: $font-bold;
  margin: auto;
}

.input-container input {
  padding: 8px 10px;
  border-radius: 50px;
  border: 1px solid $grey;
  font-size: 14px;
  font-family: $font-regular;
  width: 400px;
  min-height: 20px;
  margin: 10px;
}
.form-container {
  margin: 20px;
  display: flex;
  flex-direction: column;
}
.form {
  margin: 10px;
  display: flex;
  flex-direction: row;
}
.stand-modal-button {
  margin: 20px auto;
  display: flex;
}

button {
  margin: 0px 10px 0px 10px;
}
.stand-modal-container {
  padding: 10px;
}

.stand-modal-close {
  cursor: pointer;
  margin: auto 15px auto auto;
}

.stand-modal-close:before {
  color: #fff;
  font-size: 20px;
}

// .stand-modal-close:hover:before {
//   color: $main-color;
// }

.stand-modal-component {
  overflow-y: auto;
  max-height: 75vh;
}

/* products modal */

.products-modal-box {
  position: fixed;
  top: 5%;
  width: $box-width;
  max-width: 95%;
}

.products-modal-header {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 5px 0;
  margin-bottom: 5px;
}

.products-modal-box .stand-modal-title {
  margin: 0;
}

.products-modal-box .stand-modal-close {
  top: 10px;
}

.products-modal-container {
  width: 100%;
  display: flex;
}

.products-modal-list {
  width: calc(23% - 5px);
  margin-right: 5px;
}

.products-modal-content {
  width: 77%;
}

@media screen and (max-width: 1000px) {
  .products-modal-container {
    flex-direction: column;
  }

  .products-modal-content,
  .products-modal-list {
    width: 100%;
  }
}

@media screen and (max-width: 625px) {
  .stand-modal-container {
    padding: 20px;
  }
}

@media screen and (max-width: 1400px) {
  .stand-modal {
    width: 90%;
  }
}
</style>