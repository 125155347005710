import Vue from "vue";
import Router from "vue-router";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "*",
      redirect: "/login",
    },
    {
      path: "/lobby",
      name: "Lobby",
      component: () => import("./views/Lobby.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/",
      name: "Home",
      component: () => import("./views/Home.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/lecture-halls",
      name: "LectureHall",
      component: () => import("./views/LectureHall.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/appointments",
      name: "Appointment",
      component: () => import("./views/Appointment.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/matchmaking",
      name: "Matchmaking",
      component: () => import("./views/Matchmaking.vue"),
      meta: {
        requiresAuth: true,
        beforeEnter: (to, from, next) => {
          if (!store.state.CONFIG.networking) next({ name: "Login" });
          else next();
        },
      },
    },
    {
      path: "/technical-sessions",
      name: "TechnicalSessions",
      component: () => import("./views/TechnicalSession.vue"),
      meta: {
        requiresAuth: true,
        beforeEnter: (to, from, next) => {
          if (!store.state.CONFIG.technicalHall) next({ name: "Login" });
          else next();
        },
      },
    },

    {
      path: "/contact-details/:id",
      name: "ContactDetails",
      component: () => import("./views/ContactDetails.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/my-bookmarks",
      name: "MyBookMarks",
      component: require("./views/MyBookMarks.vue").default,

      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/my-profile",
      name: "MyProfile",
      component: () => import("./views/MyProfile.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/edit-profile",
      name: "EditProfile",
      component: () => import("./views/EditProfile.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/my-settings",
      name: "MySettings",
      component: () => import("./views/MySettings.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/my-calendar",
      name: "MyCalendar",
      component: () => import("./views/MyCalendar.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/chat",
      name: "Chat",
      component: () => import("./views/Chat.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/exhibitor-search-results",
      name: "ExhibitorSearchResults",
      component: () => import("./views/ExhibitorSearchResults.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/change-password",
      name: "ChangePassword",
      component: () => import("./views/ChangePassword.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/reset-password/:token",
      name: "ResetPassword",
      component: () => import("./views/ResetPassword.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/stand/:id",
      name: "Stand",
      component: () => import("./views/Stand.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () => import("./views/Login.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/inspection",
      name: "Inspection",
      component: () => import("./views/Inspection.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/forgot-password",
      name: "ForgotPassword",
      component: () => import("./views/ForgotPassword.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/register",
      name: "Register",
      component: () => import("./views/Register.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/welcome",
      name: "Welcome",
      component: () => import("./views/Welcome.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: "/versions",
      name: "Versions",
      component: () => import("./views/Versions.vue"),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/call-video",
      name: "Jitsi",
      component: () => import("./views/Jitsi.vue"),
      meta: {
        requiresAuth: false,
      },
    },
    // {
    //   path: "/awards",
    //   name: "Awards",
    //   component: () => import("./views/Awards.vue"),
    //   meta: {
    //     requiresAuth: true,
    //   },
    // },
  ],
});

/* verification qu'on est connecté sinon on redirige */
router.beforeEach((to, from, next) => {
  if (
    !(from.name == "ContactDetails" && to.name == "Matchmaking") &&
    !(from.name == "Matchmaking" && to.name == "ContactDetails")
  ) {
    store.commit("setFilterAttendees", []);
    store.commit("setAtmosphereImg", null);
  }
  if (from.name == "Chat") {
    // store.commit("setMyConversations", []);
    store.commit("setSelectChatMember", {});
  }
  store.commit("errorClose");
  store.commit("closeMenu");
  const currentUser = store.getters.getAuth;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next("/login");
  else next();
});

export default router;
